<template>
  <div class="self-learning-mode-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <template v-if="this.errorMsg !== null">
            <ErrorMsgBox :contents="this.errorMsg"/>
          </template>
          <template v-else>
            <div class="wi-body-wrap">
              <div class="sec-left-wrap">
                <div class="sec-tab-wrap">
                  <p>범위 선택</p>
                  <div class="st-divider"></div>
                  <div class="select-wrap">
                    <select v-model="this.selectRange" @change="this.onChangeRange">
                      <option value="null" hidden>선택</option>
                      <option v-for="(name, index) in this.rangeOptions" :key="index" :value="name">{{ name }}</option>
                    </select>
                    <template v-if="this.selectRange !== null">
                      <img src="@/assets/images/arrow-right-7.png" alt="">
                      <select v-model="this.selectSubRange" @change="this.onChangeSubRange">
                        <option value="null" hidden>선택</option>
                        <option v-for="(name, index) in this.rangeSubOptions" :key="index" :value="name">{{ name }}</option>
                      </select>
                    </template>
                  </div>
                </div>
                <div class="sec-list-wrap">
                  <V2TreeList
                    :nodes="this.treeNodes"
                    :on-node-checked="this.onNodeChanged"
                    :on-node-unchecked="this.onNodeChanged"
                  />
                </div>
              </div>
              <div class="sec-right-wrap">
                <div class="sec-tab-list">
                  <template v-for="(tabName, index) in this.tabs" :key="index">
                    <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                         @click="() => this.selectTabIndex = index"
                    >
                      <p>{{ tabName }}</p>
                      <div></div>
                    </div>
                  </template>
                </div>
                <div class="sec-body-wrap">
                  <template v-for="(unitItem, index) in this.unitItems" :key="index">
                    <div class="sb-item">
                      <h5>{{ unitItem.title }}</h5>
                      <div class="sbi-list-wrap">
                        <template v-for="(item, index) in unitItem.items" :key="index">
                          <button :class="['il-item', {'active': item.isSelect}]"
                                  @click="() => item.isSelect = !item.isSelect"
                          >
                            <span>{{ item.name }}</span>
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="sec-button-wrap">
                  <button>
                    <span>작성 완료</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import V2TreeList from "@/components/V2TreeList.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import Models from "@/models";
import SelfStudyService from "@/services/selfStudy.service";
import utils from "@/utils";

export default {
  components: {ErrorMsgBox, V2TreeList, V2Wrapper},
  data() {
    return {
      errorMsg: null,
      isShowLoadingDialog: false,

      rangeOptions: Models.schoolTypeList(),
      selectRange: null,
      selectSubRange: null,

      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,

      treeNodes: {},
    };
  },
  mounted() {
  },
  methods: {
    async onNodeChanged() {
      utils.debugLog('onNodeChanged()');
      const treeNodes = this.treeNodes;
      utils.debugLog(treeNodes);

      const selectPtnIds = [];
      let diagPtnPreCnt = 0;
      Object.entries(treeNodes).forEach(entries => {
        const value = entries[1];
        if (value.depth == 3 && value.state?.checked) {
          selectPtnIds.push(value.pattern_id);
          diagPtnPreCnt += value.pre_ptn_cnt ?? 0;
        }
      });

      this.selectDiagPtnIds = selectPtnIds;
      this.selectDiagPtnPreCnt = diagPtnPreCnt;
    },
    onChangeRange() {
      this.selectSubRange = null;
    },
    async onChangeSubRange() {
      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) {
        this.$toast.error('범위를 모두 선택해주세요');
        return;
      }
      this.treeNodes = {};

      const schoolType = selectRange;
      const params = {
        'school_type': schoolType,
        'is_node_list': true,
      };
      if (schoolType == '고등학교') {
        params['problem_subject'] = selectSubRange;
      } else {
        params['school_year'] = selectSubRange.split('-')[0];
        params['term'] = selectSubRange.split('-')[1];
      }

      let res;
      try {
        res = await SelfStudyService.getSelfLearningModeList(params);
      } catch (e) {
        this.$toast.error('유형 리스트를 불러오는 중 오류가 발생하였습니다');
        console.error(e);
        return;
      }

      utils.debugLog(res);
      this.treeNodes = res;
    },
  },
}
</script>
