import api from "./api"

class SelfStudyService {
    getSelfLearningModeList(params) {
        return api.get('/self_study/self_leaning_mode_list', { params });
    }

    async createTeacherDiagEval(payload) {
        try {
            await api.post('/self_study/create_teacher_diag_eval', payload, {
                headers: {"Content-Type": "multipart/form-data"},
            });
            return true;
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    async getStudentSelfLeaningList(pageSize, page) {
        const params = {
            'page_size': pageSize,
            'page': page,
        }

        try {
            return await api.get('/self_study/student_self_leaning_list', { params });
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    async getDiagEvalStu(diagEvalStuId) {
        try {
            return await api.get(`/self_study/diag_eval_stu/${diagEvalStuId}`);
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    async submitDiagEvalStuPrb(diagEvalStuPrbId, payload) {
        try {
            return await api.post(`/self_study/diag_eval_stu_prb/${diagEvalStuPrbId}`, payload, {
                headers: {"Content-Type": "multipart/form-data"},
            });
        } catch (e) {
            console.error(e);
        }
        return null;
    }
}

export default new SelfStudyService();
