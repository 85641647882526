<template>
  <div :class="['mypage-tab-body-wrap']" style="padding-bottom: 0">
    <Filter
        :filterData="this.filterData"
    />

    <ListArray
        :listData="this.listData"
        :paginateData="this.paginateData"
        :onPageChange="this.changeStudentPage"
        :isLoading="this.isLoading"
    >
    </ListArray>

    <div :class="['select-list', {'open': checkStudents.length > 0}]">
      <h1>학생 {{ checkStudents.length }}명 선택됨</h1>

      <div class="btn-list">
        <div class="btn-item" @click="() => onChangeIncludeStudent(false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="block" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: white;"><path fill="#fff" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zM2.667 8c0-2.947 2.386-5.333 5.333-5.333 1.233 0 2.367.42 3.267 1.126l-7.474 7.474c-.706-.9-1.126-2.034-1.126-3.267zM8 13.333c-1.233 0-2.367-.42-3.267-1.126l7.474-7.474c.706.9 1.126 2.034 1.126 3.267 0 2.947-2.386 5.333-5.333 5.333z"></path></svg>
          <span>퇴원 처리</span>
        </div>
        <div class="close-btn" @click="() => checkStudents.forEach(i => i.isChecked = false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <StudentPopup
      :isVisible="isOpenStudentPopup"
      :academyUserId="this.selectStudentId"
      :onClose="() => {
        this.selectStudentId = null;
        this.isOpenStudentPopup = false;
      }"
      :onUpdated="() => {
        this.selectStudentId = null;
        this.isOpenStudentPopup = false;
        this.refreshStudentData();
      }"
      :onCreated="() => {
        this.selectStudentId = null;
        this.isOpenStudentPopup = false;
        this.changeStudentPage(1);
      }"
    />
  </Teleport>
</template>

<script>
import Filter from './Filter.vue'
import ListArray from './ListArray.vue'
import Popup from '@/components/Popup.vue'
import academyService from '../services/academy.service'
import utils from '../utils'
import StudentPopup from './StudentPopup.vue'

export default {
  name: 'OperationTeacher',
  components: {
    Filter,
    Popup,
    ListArray,
    StudentPopup,
  },
  props: {
    academyInfo: Object,
  },
  watch: {
    academyInfo() {
      this.changeStudentPage(1);
      this.refreshRequestData();
    },
    isVisibleRequest() {
      this.changeStudentPage(1);
      this.refreshRequestData();
    },
  },
  data() {
    return {
      isVisibleRequest: false,
      requests: [],
      students: [],
      selectStudentId: null,
      isOpenStudentPopup: false,
      paginateData: {
        'size': 10,
        'page': 1,
        'totalPage': 1,
        'maxVisiblePage': 1 + 5 + 5,
      },
      isLoading: true,
    }
  },
  created() {
    this.onChangeTab(this.selectTabIndex);
    this.changeStudentPage(1);
    this.refreshRequestData();
  },
  methods: {
    async refreshRequestData() {
      this.requests = [];

      this.requests = await academyService.getAcademyMemberRequests({
        academy_info_id: this.academyInfo.id,
        is_student: true,
        is_accept: false,
        is_reject: false,
      });
    },
    async changeStudentPage(page) {
      this.paginateData.page = page;
      await this.refreshStudentData();
    },
    async refreshStudentData() {
      if (!this.academyInfo) return;

      this.students = [];
      this.isLoading = true;
      this.selectStudentId = null;

      const {students, page, page_size, total_pages} = await academyService.getStudents(
        50,
        this.paginateData.page,
      );
      this.isLoading = false;
      this.students = students;
      this.paginateData.page = page;
      this.paginateData.totalPage = total_pages;
      this.selectStudentId = null;
    },
    onChangeTab(index) {
      this.selectTabIndex = index
    },
    async acceptMemberRequest(request) {
      await academyService.acceptMemberRequest(request.id)
      this.$toast.success('승인 되었습니다')
      await this.refreshRequestData();
    },
    async rejectMemberRequest(request) {
      await academyService.rejectMemberRequest(request.id)
      this.$toast.success('거부 되었습니다')
      await this.refreshRequestData();
    },
    onChangeItem(item) {
      const findItem = this.students.find(i => i.id == item.id)
      if (findItem) {
        findItem.isChecked = !findItem.isChecked
      }
    },
    async onChangeIncludeStudent(isInclude) {
      await Promise.all([
        ...this.checkStudents.map(async (student) => {
          return await academyService.updateAcademyUser(student.id, {
            is_include: isInclude,
          });
        }),
      ]);

      this.$toast.success(`${isInclude ? '재원' : '퇴원'} 처리 되었습니다`)

      await this.refreshStudentData();
      await this.refreshStudentData();
    },
  },
  computed: {
    filterData() {
      return {
        title: '목록 필터',
        list: [
          {type: 'checkbox', addClass: '', name: `요청 건(${Number(this.requests.length)}) 보기`, value: this.isVisibleRequest, onChange: (value) => this.isVisibleRequest = value},
        ],
        searchInput: {isHidden: true},
        createBtn: {isVisible: true, onCreate: () => this.isOpenStudentPopup = true, name: '학생 등록'}
      }
    },
    listData() {
      return {
        title: '학생',
        heads: this.isVisibleRequest
            ? [
              {title: '요청자명', type: 'string', addClass: 'flex-1'},
              {title: '요청일시', type: 'string', addClass: 'w-100px'},
              {title: '승인', type: 'submit-btn', addClass: 'w-110px'},
              {title: '거부', type: 'submit-btn', addClass: 'w-110px'},
            ]
            : [
              {title: '선택', type: 'checkbox', addClass: 'w-75px'},
              {title: '학년', type: 'string', addClass: 'w-100px'},
              {title: '학생명', type: 'string', addClass: 'flex-1'},
              {title: '연락처', type: 'string', addClass: 'w-200px'},
              {title: '이메일', type: 'string', addClass: 'w-200px'},
              {title: '재원 상태', type: 'string', addClass: 'w-100px'},
              {title: '상세', type: 'submit-btn', addClass: 'w-100px'},
            ],
        trList: this.isVisibleRequest
            ? this.requests.map(request => {
              return [
                {value: request.user.name},
                {value: utils.getDateByServer(request.create_dt)},
                {value: '승인하기', onClickSubmitBtn: () => this.acceptMemberRequest(request), addClass: 'active-btn'},
                {value: '거부하기', onClickSubmitBtn: () => this.rejectMemberRequest(request), addClass: 'red-btn'},
              ]
            })
            : this.students.map(student => {
              const result = [
                {isChecked: student.isChecked, onChange: () => this.onChangeItem(student)},
                {value: student.grade},
                {value: student.name},
                {value: student.phone},
                {value: student.email},
                {value: student.include_state},
                {value: '상세', onClickSubmitBtn: () => {
                  this.selectStudentId = student.id;
                  this.isOpenStudentPopup = true;
                }},
              ]

              result.forEach(i => {
                i.onClick = () => this.onChangeItem(student)
              })

              return result
            })
      }
    },
    checkStudents() {
      return this.students.filter(i => i.isChecked)
    },
  },
}
</script>
