<template>
  <div class="student-wrong-note-wrap">
    <V2Wrapper>
      <div class="swnw-inner">
        <div class="v2-base-container">
          <div class="wi-search-wrap">
            <div class="sw-left-wrap">
              <p>기간별 오답노트 기록</p>
              <div class="swl-wrap">
                <div class="swl-item"
                     @click="() => this.$refs.rangeStartDt.showPicker()">
                  <input type="date"
                         @change="(event) => this.onChangeRangeEvent(0, event)"
                         ref="rangeStartDt">
                  <span>{{ this.rangeStartDtStr }}</span>
                  <img src="@/assets/images/calendar.png" alt="">
                </div>
                <span>~</span>
                <div class="swl-item"
                     @click="() => this.$refs.rangeEndDt.showPicker()">
                  <input type="date"
                         @change="(event) => this.onChangeRangeEvent(1, event)"
                         ref="rangeEndDt">
                  <span>{{ this.rangeEndDtStr }}</span>
                  <img src="@/assets/images/calendar.png" alt="">
                </div>
              </div>
            </div>
            <div class="sw-right-wrap">
              <label>
                <input
                    v-model="this.searchKeyword"
                    type="text"
                    placeholder="오답노트 이름 검색"
                >
                <img src="@/assets/images/search-2.png" alt=""/>
              </label>
              <button @click="() => this.isShowWrongNoteCreatePopup = true">
                <span>오답노트 만들기</span>
              </button>
            </div>
          </div>
          <div class="wi-table-list-wrap">
            <div class="tl-line">
              <div class="tl-item"></div>
              <div class="tl-item">
                <p class="li-text">오답노트 제목</p>
              </div>
              <div class="tl-item">
                <p class="li-text">생성일</p>
              </div>
              <div class="tl-item">
                <p class="li-text">문제수</p>
              </div>
              <div class="tl-item"></div>
            </div>
            <template v-if="this.errorMsg !== null">
              <ErrorMsgBox :contents="this.errorMsg"/>
            </template>
            <template v-else-if="this.isLoading">
              <div class="wi-loading">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template v-else>
              <template v-for="(studentWorkFolderVo, index) in this.searchedStudentWorkFolderVos" :key="index">
                <div class="tl-line"
                     @click="() => this.$router.push({ name: 'StudentWrongNoteDetail', params: {folderId: studentWorkFolderVo.id} })"
                >
                  <div class="tl-item">
                    <img class="folder-img"
                         src="@/assets/images/folder.png"
                         alt=""
                    />
                  </div>
                  <div class="tl-item">
                    <p class="li-text">{{ studentWorkFolderVo.name }}</p>
                  </div>
                  <div class="tl-item">
                    <p class="li-text">{{ studentWorkFolderVo.getCreateDateStr() }}</p>
                  </div>
                  <div class="tl-item">
                    <img class="doc-img" src="@/assets/images/document-2.png" alt=""/>
                    <p class="li-text">{{ studentWorkFolderVo.problemCnt }}</p>
                  </div>
                  <div class="tl-item">
                    <b-dropdown
                        dropleft
                        class="etc-btn"
                        onclick="event.stopPropagation()"
                        ref="etcDropdown"
                    >
                      <template #button-content>
                        <img src="@/assets/images/mypage-etc.png" alt=""/>
                      </template>
                      <b-dropdown-item
                          @click="() => {this.handleEditName(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/text-edit.png" alt=""/>
                        <span>이름 수정</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleDelete(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/delete.png" alt=""/>
                        <span>삭제</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleCopy(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/copy.png" alt=""/>
                        <span>복제</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleSolveAgain(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/loop-2.png" alt=""/>
                        <span>다시 풀기</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <WrongNoteCreatePopup
      :is-visible="this.isShowWrongNoteCreatePopup"
      :on-close="() => this.isShowWrongNoteCreatePopup = false"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteEditNamePopup
      :is-visible="this.editNameStudentWorkFolderVo !== null"
      :on-close="() => this.editNameStudentWorkFolderVo = null"
      :student-work-folder-vo="this.editNameStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteDeletePopup
      :is-visible="this.deleteStudentWorkFolderVo !== null"
      :on-close="() => this.deleteStudentWorkFolderVo = null"
      :student-work-folder-vo="this.deleteStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteCopyPopup
      :is-visible="this.copyStudentWorkFolderVo !== null"
      :on-close="() => this.copyStudentWorkFolderVo = null"
      :student-work-folder-vo="this.copyStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteSolveAgainPopup
      :is-visible="this.solveAgainStudentWorkFolderVo !== null"
      :on-close="() => this.solveAgainStudentWorkFolderVo = null"
      :student-work-folder-vo="this.solveAgainStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <LoadingDialog
      :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import utils from "@/utils";
import StudentWorkService from "@/services/studentWork.service";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import {StudentWorkFolderVo} from "@/models/student_work_folder_vo";
import YoutubeEmbedPopup from "@/components/YoutubeEmbedPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import PopupComponent from "@/components/PopupComponent.vue";
import WrongNoteEditNamePopup from "@/components/WrongNoteEditNamePopup.vue";
import WrongNoteDeletePopup from "@/components/WrongNoteDeletePopup.vue";
import WrongNoteCopyPopup from "@/components/WrongNoteCopyPopup.vue";
import WrongNoteSolveAgainPopup from "@/components/WrongNoteSolveAgainPopup.vue";
import WrongNoteCreatePopup from "@/components/WrongNoteCreatePopup.vue";

export default {
  name: 'StudentWrongNote',
  components: {
    WrongNoteCreatePopup,
    WrongNoteSolveAgainPopup,
    WrongNoteCopyPopup,
    WrongNoteDeletePopup,
    WrongNoteEditNamePopup, PopupComponent, LoadingDialog, YoutubeEmbedPopup, ErrorMsgBox, V2Wrapper},
  data() {
    const nowDt = new Date(utils.parseYmd(new Date()));
    const startDt = new Date(nowDt);
    startDt.setFullYear(startDt.getFullYear() - 1);

    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      /** @type {Date} */
      minDt: new Date(`${nowDt.getFullYear() - 5}-01-01`),
      /** @type {Date} */
      maxDt: new Date(nowDt),
      /** @type {Date} */
      rangeStartDt: startDt,
      /** @type {Date} */
      rangeEndDt: new Date(nowDt),
      /** @type {StudentWorkFolderVo[]} */
      studentWorkFolderVos: [],
      /** @type {string} */
      searchKeyword: '',
      /** @type {boolean} */
      isLoadingDialog: false,
      /** @type {boolean} */
      isShowWrongNoteCreatePopup: false,
      /** @type {StudentWorkFolderVo|null} */
      editNameStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      deleteStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      copyStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      solveAgainStudentWorkFolderVo: null,
    };
  },
  mounted() {
    this.$refs.rangeStartDt.valueAsDate = this.rangeStartDt;
    this.$refs.rangeEndDt.valueAsDate = this.rangeEndDt;

    this.refreshWrongNoteList();
  },
  methods: {
    onChangeRangeEvent(type, event) {
      console.log(event.target);
      const dtVal = event.target.value;
      console.log(dtVal);
      if (dtVal.length == 0) {
        this.$toast.warning('기간을 선택해주세요');
        this.resetRangeDt(type, event.target);
        return;
      }
      const dt = new Date(dtVal);

      if (type == 0) {
        const maxDt = new Date(this.rangeEndDt.getTime());
        if (dt.getTime() >= maxDt.getTime()) {
          this.$toast.warning('시작 일자는 종료 일자보다 크거나 같을 수 없습니다');
          this.resetRangeDt(type, event.target);
          return;
        }
        this.rangeStartDt = dt;
      } else {
        const nowDt = new Date();
        if (dt.getTime() > nowDt.getTime()) {
          this.$toast.warning('오늘보다 이후 날짜는 선택할 수 없습니다');
          this.resetRangeDt(type, event.target);
          return;
        }
        this.rangeEndDt = dt;
      }
      event.target.valueAsDate = dt;
    },
    resetRangeDt(type, elem) {
      if (type == 0) {
        elem.valueAsDate = this.rangeStartDt;
      } else {
        elem.valueAsDate = this.rangeEndDt;
      }
    },
    async refreshWrongNoteList() {
      this.isLoading = true;

      let res;
      try {
        res = await StudentWorkService.getFolders();
        const studentWorkFolderVos = res.map((e) => StudentWorkFolderVo.fromJson(e));
        utils.debugLog(`folder response length: ${studentWorkFolderVos.length}`);
        this.studentWorkFolderVos = studentWorkFolderVos;
      } catch (e) {
        console.log(e);
        this.errorMsg = '오답 노트 리스트를 받아오는 중 오류가 발생하였습니다';
        return;
      }

      this.isLoading = false;
    },
    handleEditName(studentWorkFolderVo) {
      this.editNameStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleDelete(studentWorkFolderVo) {
      this.deleteStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleCopy(studentWorkFolderVo) {
      this.copyStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleSolveAgain(studentWorkFolderVo) {
      this.solveAgainStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    hideDropDown() {
      const etcDropdowns = this.$refs.etcDropdown;
      etcDropdowns.forEach((e) => {
        e.hide();
      });
    },
  },
  computed: {
    rangeStartDtStr() {
      return utils.parseYmd(this.rangeStartDt);
    },
    rangeEndDtStr() {
      return utils.parseYmd(this.rangeEndDt);
    },
    searchedStudentWorkFolderVos() {
      return this.studentWorkFolderVos.filter((studentWorkFolderVo) => {
        if (utils.isIncludeBetweenDate(studentWorkFolderVo.createDt, this.rangeStartDt, this.rangeEndDt) === false) return false;
        if (studentWorkFolderVo.name.includes(this.searchKeyword) === false) return false;

        return true;
      });
    },
  },
}
</script>
