<template>
  <div class="teacher-lesson-wrap">
    <V2Wrapper>
      <div class="tlw-inner">
        <template v-if="this.isLoading">
          <div class="wi-loading">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                variant="primary"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template v-else>
          <div class="wi-body-wrap">
            <div :class="['side-nav-expand-btn', {'expand': this.isSideNavExpand}]"
                 @click="() => this.isSideNavExpand = true"
            >
              <img class="expand-arrow-btn" src="@/assets/images/arrow-right.png" alt="">
            </div>
            <div :class="['side-nav-wrap', {'expand': this.isSideNavExpand}]">
              <div class="sn-header-wrap"
                   @click="() => this.isSideNavExpand = false">
                <p>공유된 페이지</p>
                <img class="expand-arrow-btn" src="@/assets/images/arrow-left.png" alt="">
              </div>
              <div class="sn-sub-header-wrap"
                   @click="() => this.onClickMenu(null)">
                <p>📕 에스덕 온보딩 보드</p>
              </div>
              <div class="sn-body-wrap">
                <div class="menu-item-list">
                  <template v-for="(menuItem, index) in this.sideNavMenuItems" :key="index">
                    <div :class="[
                        'menu-item',
                        `d-${menuItem.depth}`,
                         {'active': menuItem.id === this.selectMenuId, 'fold': menuItem.isFold, 'hide': menuItem.isHide}
                        ]"
                         @click="() => this.onClickMenu(menuItem.id)"
                         @contextmenu="this.onClickRightMenu($event, menuItem)">
                      <div class="li-menu-icon"
                           onclick="event.stopPropagation();"
                           @click="() => this.onClickMenuIcon(menuItem)">
                        <img src="@/assets/images/file.png" alt=""/>
                        <img src="@/assets/images/arrow-right-7.png" alt=""/>
                      </div>
                      <p class="li-text">{{ menuItem.title }}</p>
                      <div class="li-hover-btn-list">
                        <template v-if="this.isReadOnly === false">
                          <button @click="() => this.onClickEditMenu(menuItem)"
                                  onclick="event.stopPropagation();">
                            <img src="@/assets/images/edit-2.png" alt="">
                          </button>
                        </template>
                        <template v-if="this.isReadOnly === false && menuItem.depth < 3">
                          <button @click="() => this.onClickAddMenu(menuItem.id, null)"
                                  onclick="event.stopPropagation();">
                            <img src="@/assets/images/plus-2.png" alt="">
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-if="this.isReadOnly === false">
                    <div class="menu-item"
                         @click="() => this.onClickAddMenu(null, null)">
                      <img class="li-plus-icon" src="@/assets/images/plus.png" alt="">
                      <p class="li-text">페이지 추가</p>
                    </div>
                  </template>
                </div>
                <div class="user-item-list">
                  <template v-for="(userItem, index) in this.userItems" :key="index">
                    <div class="user-item">
                      <div class="liu-thumb">
                        <img :src="userItem.thumbnailUrl" alt=""/>
                      </div>
                      <p>{{ userItem.name }}</p>
                      <div :class="['liu-online', {'active': userItem.isOnline}]"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="v2-base-container">
              <div :class="['bc-loading', {'active': this.isLoadingContents}]">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    variant="primary"
                    label="Spinning"
                ></b-spinner>
              </div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.selectMenuId === null,
              }]">페이지를 먼저 선택해주세요.</div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.isContentsNotFound,
              }]">해당 메뉴를 찾을 수 없습니다.</div>
              <div :class="['bc-inner-wrap', {
                'active': this.isLoadingContents === false && this.selectMenuId !== null,
              }]">
                <div id="editorjs"
                     ref="editorJsWrap"
                ></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <EditMenuPopup
      :edit-menu="this.showEditMenu"
      :on-close="() => this.showEditMenu = null"
      :on-change="this.onChangeBoardHeaderTitle"
    />
    <RightMenu
      :is-visible="this.rightMenuId !== null"
      :on-close="() => this.hideRightMenu()"
      :client-x="this.rightMenuClientX ?? 0"
      :client-y="this.rightMenuClientY ?? 0"
      :items="this.rightMenuItems"
    />
    <LoadingDialog
      :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import BoardService from "@/services/board.service";
import ProblemSuggestPopup from "@/components/ProblemSuggestPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import { debounce } from 'lodash'
import EditMenuPopup from "@/components/EditMenuPopup.vue";
import utils from "@/utils";
import RightMenu from "@/components/RightMenu.vue";
import {mapGetters} from "vuex";
import AcademyService from "@/services/academy.service";

export default {
  name: 'TeacherLesson',
  components: {
    RightMenu,
    EditMenuPopup,
    LoadingDialog,
    ProblemSuggestPopup,
    V2Wrapper,
  },
  watch: {
    $route (to, from) {
      const selectMenuId = parseInt(to.query.menuId ?? null);
      this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
    },
    async selectMenuId() {
      this.isLoadingContents = true;
      this.$refs.editorJsWrap.innerHTML = '';
      const selectMenuId = this.selectMenuId;

      utils.debugLog(`selectMenuId => ${selectMenuId}`);
      if (selectMenuId !== null) {
        const res = await BoardService.getBoardContents(selectMenuId);
        this.isContentsNotFound = !res.is_exist;
        if (res.is_exist) {
          const targetEditorId = ++this.editorId;
          const boardContentsId = res.id;
          const initContents = res.contents.length > 0 ? JSON.parse(res.contents) : {};

          const editor = new window.EditorJS({
            holder: 'editorjs',
            autofocus: true,
            minHeight: 30,
            inlineToolbar: [
              'link',
              'bold',
              'italic',
            ],
            data: initContents,
            placeholder: this.isReadOnly ? '' : '여기 내용을 입력해주세요.',
            readOnly: this.isReadOnly,
            onReady: () => {},
            onChange: async (api, event) => {
              utils.debugLog(`onChange() - targetEditorId: ${targetEditorId}, nowEditorId: ${this.editorId}, boardContentsId: ${boardContentsId}, isReadOnly: ${this.isReadOnly}`);
              await this.onBoardChangeEvent(targetEditorId, editor, boardContentsId);
            },
            tools: {
              header: {
                class: window.Header,
                inlineToolbar: true,
              },
              embed: {
                class: window.Embed,
                inlineToolbar: true,
              },
              image: {
                class: window.InlineImage,
                inlineToolbar: true,
                config: {
                  embed: {
                    display: true,
                  },
                },
              },
            },
          });
        }
      }
      this.isLoadingContents = false;
    },
  },
  data() {
    const sampleNames = [
      '강기복',
      '도레미',
      '라분발',
    ];
    const sampleThumbnailUrls = [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1520626337972-ebf863448db6?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];

    return {
      editorId: 0,
      isLoading: true,
      isLoadingDialog: false,
      isLoadingContents: true,
      isContentsNotFound: false,
      isSideNavExpand: false,
      isReadOnly: false,

      showEditMenu: null,
      selectMenuId: null,
      menuItems: [],
      userItems: [],
      foldMenuIds: [],

      // right menu
      rightMenuId: null,
      rightMenuClientX: null,
      rightMenuClientY: null,
      rightMenuItems: [],
      sampleThumbnailUrls,
    };
  },
  mounted() {
    Promise.all([
      this.$loadScript('/js/editorjs.js'),
      this.$loadScript('/js/editorjs-embed.js'),
      this.$loadScript('/js/editorjs-header.js'),
      this.$loadScript('/js/editorjs-inline-image.js'),
    ]).then(() => {
      this.initData();
    });

    this.initUserItems();
  },
  methods: {
    onClickMenuIcon(menuItem) {
      const menuId = menuItem.id;
      const foldMenuIds = this.foldMenuIds;

      if (menuItem.isEmpty) {
        this.onClickMenu(menuId);
        return;
      }

      if (foldMenuIds.includes(menuId)) {
        this.foldMenuIds = foldMenuIds.filter((e) => e != menuId);
      } else {
        this.foldMenuIds.push(menuId);
      }
    },
    async initUserItems() {
      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) return;

      const selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) return;

      const res = await AcademyService.getTeacherLessonUserList(selectedAcademyClassId);
      if (res === null) return;
      utils.debugLog(res);

      this.userItems = (res.student_items ?? []).map((e) => {
        return {
          thumbnailUrl: this.sampleThumbnailUrls[Math.floor(Math.random() * this.sampleThumbnailUrls.length)],
          name: e.name,
          isOnline: e.is_online,
        };
      });
    },
    onClickEditMenu(menuItem) {
      this.showEditMenu = Object.assign({}, menuItem);
    },
    hideRightMenu() {
      this.rightMenuId = null;
    },
    onClickRightMenu(event, menuItem) {
      event.preventDefault();

      this.rightMenuClientX = event.clientX;
      this.rightMenuClientY = event.clientY;
      this.rightMenuId = menuItem.id;

      const items = [];
      if (this.isReadOnly === false) {
        items.push({
          title: '이름수정',
          onClick: () => {
            this.hideRightMenu();
            this.onClickEditMenu(menuItem);
          },
        });
      }
      if (this.isReadOnly === false) {
        items.push({
          title: '메뉴삭제',
          onClick: () => {
            this.hideRightMenu();
            this.onClickDeleteMenu(menuItem.id);
          },
        });
      }
      if (this.isReadOnly === false) {
        if (menuItem.depth < 3) {
          items.push({
            title: '하위메뉴추가',
            onClick: () => {
              this.hideRightMenu();
              this.onClickAddMenu(menuItem.id, null);
            },
          });
        }
      }
      items.push({
        title: '공유페이지 링크복사',
        onClick: () => {
          const shareLink = this.$router.resolve({name: 'TeacherLessonShare', params: {menuId: menuItem.id}}).href;
          const shareFullLink = `${window.location.origin}${shareLink}`;
          utils.debugLog(`shareFullLink: ${shareFullLink}`);
          utils.writeClipboardText(shareFullLink);
          this.$toast.success('링크가 복사되었습니다');
          this.hideRightMenu();
        }
      });
      this.rightMenuItems = items;
    },
    async onBoardChangeEvent(targetEditorId, editor, boardContentsId) {
      if (targetEditorId != this.editorId) return;
      if (this.isReadOnly) return;

      const contents = await editor.save();
      const encodedContents = JSON.stringify(contents);
      await BoardService.editBoardContents(boardContentsId, encodedContents);
    },
    async initData() {
      this.isReadOnly = this.getUser?.email !== 'admin@admin.com';

      const res = await BoardService.getBoardMenus();
      this.menuItems = res ?? [];

      this.isLoading = false;

      setTimeout(() => {
        const selectMenuId = parseInt(this.$route.query.menuId ?? null);
        this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
        this.isLoadingContents = false;
      }, 500);
    },
    async onClickAddMenu(upperMenuId, title) {
      this.isLoadingDialog = true;

      const res = await BoardService.createMenu(upperMenuId, title);
      utils.debugLog('onClickAddMenu res =>');
      utils.debugLog(res);
      this.menuItems = res ?? [];

      this.isLoadingDialog = false;
    },
    async onClickDeleteMenu(menuId) {
      this.isLoadingDialog = true;

      const res = await BoardService.deleteMenu(menuId);
      utils.debugLog('deleteMenu res =>');
      utils.debugLog(res);
      this.menuItems = res ?? [];

      if (menuId === this.selectMenuId) {
        this.selectMenuId = null;
      }

      this.isLoadingDialog = false;

      this.onClickMenu(null);
    },
    onClickMenu(menuId) {
      const query = Object.assign({}, this.$route.query);
      if (menuId === null) {
        delete query.menuId;
      } else {
        query.menuId = menuId;
      }
      this.$router.push({ name: this.$route.name, query: query });
    },
    onChangeBoardHeaderTitle(menuId, value) {
      this.boardHeaderTitleSaveToServer(this, menuId, value);
    },
    boardHeaderTitleSaveToServer: debounce(async (appContext, menuId, title) => {
      const res = await BoardService.editMenu(menuId, title);
      utils.debugLog('boardHeaderTitleSaveToServer res =>');
      utils.debugLog(res);
      appContext.menuItems = res ?? [];
    }, 200),
  },
  computed: {
    sideNavMenuItems() {
      const foldMenuIds = this.foldMenuIds;
      const resultList = [];

      for (const menuItem of this.menuItems) {
        const d1Items = menuItem.sub_items ?? [];
        let isFold = foldMenuIds.includes(menuItem.id);
        let isD0Fold = isFold;
        resultList.push({
          depth: 0,
          id: menuItem.id,
          title: menuItem.title,
          isFold: isFold,
          isHide: false,
          isEmpty: d1Items.length === 0,
        });

        for (const d1Item of d1Items) {
          const d2Items = d1Item.sub_items ?? [];
          isFold = foldMenuIds.includes(d1Item.id);
          let isD1Fold = isFold;
          resultList.push({
            depth: 1,
            id: d1Item.id,
            title: d1Item.title,
            isFold: isFold,
            isHide: isD0Fold,
            isEmpty: d2Items.length === 0,
          });

          for (const d2Item of d2Items) {
            const d3Items = d2Item.sub_items ?? [];
            isFold = foldMenuIds.includes(d2Item.id);
            let isD2Fold = isFold;
            resultList.push({
              depth: 2,
              id: d2Item.id,
              title: d2Item.title,
              isFold: isFold,
              isHide: isD0Fold || isD1Fold,
              isEmpty: d3Items.length === 0,
            });

            for (const d3Item of d3Items) {
              isFold = foldMenuIds.includes(d3Item.id);
              resultList.push({
                depth: 3,
                id: d3Item.id,
                title: d3Item.title,
                isFold: isFold,
                isHide: isD0Fold || isD1Fold || isD2Fold,
                isEmpty: true,
              });
            }
          }
        }
      }

      return resultList;
    },
    ...mapGetters('auth', [
      'getUser',
    ]),
    ...mapGetters('classStore', [
      'getSelectedAcademyClassId',
    ]),
  },
}
</script>
